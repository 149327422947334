.MenuLogo {
  width: 75%;
}

.MenuLogo img {
  width: 200px;
  padding: 20px 0 0 5%;
}

.MainMenu_Fixed .MenuLogo img {
  width: 150px;
}

@media (min-width: 768px) {
  .MenuLogo {
    width: 30%;
  }

  .MainMenu_Fixed .MenuLogo img {
    width: 150px;
    align-self: center;
  }
}

@media (min-width: 1000px) {
  .MenuLogo {
    width: 50%;
  }

  .MenuLogo img {
    width: 250px;
    padding: 20px 0 0 10%;
  }
}
