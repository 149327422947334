.FeaturedPosts {
  background-color: #394258;
  margin: 10px 0;
  border-radius: 30px;
}

.FeaturedPosts a {
  text-decoration: none;
}

.FeaturedPosts_Title_Box {
  background-color: #1a2746;
  padding: 10px;
  border-radius: 30px 30px 0 0;
}

.FeaturedPosts_Title {
  color: lightgrey;
  font-size: 20px;
}

@media (min-width: 450px) {
  .FeaturedPosts {
    width: 360px;
    margin: 10px auto;
    border-radius: 30px;
  }
}

@media (min-width: 768px) {
  .FeaturedPosts {
    margin: 10px 1%;
  }
}
