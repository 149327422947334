.Footer p {
  color: #727272;
  padding: 0 10px;
  font-size: 20px;
}

.Footer li,
li a {
  text-align: center;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  text-decoration: none;
}

.Footer .resaltar {
  font-size: 30px;
  font-weight: 700;
  background-color: white;
  width: fit-content;
  margin: 0 auto 20px;
  border-radius: 100px;
}

.CopyrightText {
  padding: 10px 0 0;
  background-color: #060910;
}

.CopyrightText p {
  text-align: center;
  padding: 0 0 30px;
  margin: unset;
}

.FooterPatron {
  width: -webkit-fill-available;
  background-color: #060910;
  margin-bottom: -3px;
}
