.AboutSection {
  background-color: #394258;
  margin: 10px 0;
  padding-bottom: 10px;
  border-radius: 30px;
}

.AboutSection_Title_Box {
  background-color: #1a2746;
  padding: 10px;
  border-radius: 30px 30px 0 0;
}

.AboutSection_Title {
  color: lightgrey;
  font-size: 20px;
}

@media (min-width: 450px) {
  .AboutSection {
    width: 360px;
    margin: 10px auto;
    border-radius: 30px;
  }
}

@media (min-width: 768px) {
  .AboutSection {
    margin: 10px 1%;
  }
}
