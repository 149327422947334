.MobileNavigationSubMenu {
  background-color: black;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 90%;
}

.MobileMenuBack {
  padding: 67px 50px 0;
}

.MobileSubMenuList {
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileSubMenuList ul {
  list-style: none;
  margin: 10px 0 30px;
}

.MobileSubMenuList li {
  line-height: 3;
  font-size: 30px;
}

.MobileSubMenuList li svg {
  vertical-align: text-top;
}

.MobileSubMenuList .TitleSubMenuList a,
.MobileSubMenuList .TitleSubMenuList {
  vertical-align: text-top;
  font-size: 24px;
  text-align: center;
}

.MobileSubMenuList .TitleSubMenuList {
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
}

.MobileSubMenuList a,
.MobileSubMenuList span {
  color: white;
  text-decoration: none;
  font-size: 22px;
}
