.MobileMenuItem button {
  color: white;
  line-height: 2;
  font-size: 32px;
  background-color: transparent;
  border: none;
}

@media (min-width: 450px) {
  .MobileMenuItem button {
    line-height: 1.7;
    font-size: 42px;
  }
}
