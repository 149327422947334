.TopBar {
  background-color: #180b19;
  padding: 20px;
}

.TopBar span {
  color: white;
  font-size: 15px;
}

@media (min-width: 768px) {
  .TopBar span {
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
  }
}

@media (min-width: 900px) {
  .TopBar span {
    padding-right: 15%;
  }
}

@media (min-width: 1100px) {
  .TopBar span {
    padding-right: unset;
    justify-content: center;
  }
}
