/* CONTENEDORES */

.App {
  text-align: center;
}

.Black {
  background-color: #180b19;
}

.Black p {
  color: white;
}

main {
  margin-top: 70px;
}

.AdviceAndConfidence {
  margin-top: -4px;
}

/* FUENTES */

h1 {
  text-align: center;
  padding: 0 3%;
  font-size: 35px;
  line-height: 1.2em;
  font-weight: 500;
}

h2 {
  text-align: center;
  padding: 30px 3% 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.7em;
}

h3 {
  padding: 30px 3% 0;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
  font-size: 26px;
  line-height: 1.7em;
}

h4 {
  padding: 30px 3%;
  font-weight: 700;
  text-align: center;
}

p {
  padding: 15px 3%;
  margin-bottom: 0;
  text-align: justify;
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 1.6em;
  font-weight: 300;
}

b,
strong {
  font-weight: 600;
  box-shadow: inset 0 -0.5em 0 0 #fedfd6;
}

a {
  color: blue;
  font-weight: 500;
}

@media (min-width: 450px) {
  .Content {
    padding: 50px 5% 0;
  }

  .Content p {
    margin: 15px 10%;
    padding: unset;
  }
}

@media (min-width: 768px) {
  .Content {
    padding: 50px 12% 0;
  }

  .AdviceAndConfidence {
    display: flex;
  }
}

@media (min-width: 1100px) {
  .Content {
    padding: 60px 18% 0;
  }
}
