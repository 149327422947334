.FooterMenu {
  background-color: #394258;
  margin: 10px 0;
  padding-bottom: 10px;
  border-radius: 30px;
}

.FooterMenu_Title_Box {
  background-color: #1a2746;
  padding: 10px;
  border-radius: 30px 30px 0 0;
}

.FooterMenu_Title {
  color: lightgrey;
  font-size: 20px;
}

.FooterMenu ul {
  list-style: none;
  padding: 0;
}

.FooterMenu a,
.FooterMenu li {
  color: lightgrey;
  padding: 0 10px;
  line-height: 2;
  font-size: 20px;
}

.FooterMenu li p {
  text-align: center;
}

@media (min-width: 450px) {
  .FooterMenu {
    width: 360px;
    margin: 10px auto;
    border-radius: 30px;
  }
}

@media (min-width: 768px) {
  .FooterMenu {
    margin: 10px 1%;
  }
}
