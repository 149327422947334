.MainMenu {
  display: flex;
  background-color: #180b19;
  z-index: 5;
  position: absolute;
  width: 100%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: -200px -30px;
}

.MainMenu_Fixed {
  position: fixed;
  display: flex;
  width: -webkit-fill-available;
  background-color: #180b19;
  top: 0;
  z-index: 5;
}

@media (min-width: 768px) {
  .MainMenu {
    justify-content: flex-end;
  }
}
