.MainNavigationMenu {
  display: inline-flex;
  justify-content: space-around;
  width: -webkit-fill-available;
}

@media (min-width: 900px) {
  .MainNavigationMenu {
    justify-content: flex-end;
    padding-right: 10%;
  }
}
