.FooterPanel {
  background-color: #060910;
  padding: 30px 10px;
}

.FooterPanel p {
  color: rgb(201, 201, 201);
  text-align: left;
  font-size: 18px;
}

@media (min-width: 768px) {
  .FooterPanel {
    padding: 30px 3%;
  }

  .FooterPanel_Box {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 900px) {
  .FooterPanel {
    padding: 30px 8%;
  }
}
