.MobileMenuList {
  position: fixed;
  background-color: darkgreen;
  width: 100%;
  height: 100%;
  top: 0;
}

.MobileMenuCross {
  position: absolute;
  z-index: 10;
  top: 70px;
  right: 50px;
}

.MobileMenuItems {
  text-align: left;
  padding: 120px 50px 80px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: -webkit-fill-available;
}

@media (min-width: 450px) {
  .MobileMenuCross {
    top: 85px;
    right: 85px;
  }

  .MobileMenuItems {
    padding: 120px 50px 50px;
  }
}
