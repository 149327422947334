.FeaturedPostsBox {
  padding: 10px;
  display: flex;
  background-color: #f6f6f6;
  margin: 5px;
  border-radius: 30px;
}

.FeaturedPostsBox_Image {
  width: 35%;
  transition: transform 0.2s;
  border-radius: 30px;
}

.FeaturedPostsBox_Image:hover {
  transform: scale(1.1);
}

.FeaturedPostsBox span {
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0 3px;
}

@media (min-width: 768px) {
  .FeaturedPostsBox {
    margin: 20px 5px;
  }

  .FeaturedPostsBox_Image {
    max-height: 80px;
  }
}
