.cssload-loading {
  height: 83px;
  left: 50%;
  margin-left: -41.5px;
  margin-top: -41.5px;
  position: absolute;
  width: 83px;
  transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
}
.cssload-loading-circle {
  background-color: rgb(219, 219, 219);
  border-radius: 50%;
  height: 14px;
  position: absolute;
  width: 14px;
  animation: 2634.65ms cssload-loading infinite;
  -o-animation: 2634.65ms cssload-loading infinite;
  -ms-animation: 2634.65ms cssload-loading infinite;
  -webkit-animation: 2634.65ms cssload-loading infinite;
  -moz-animation: 2634.65ms cssload-loading infinite;
  transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
}
.cssload-loading-circle:nth-child(1) {
  animation-delay: 48.3ms;
  -o-animation-delay: 48.3ms;
  -ms-animation-delay: 48.3ms;
  -webkit-animation-delay: 48.3ms;
  -moz-animation-delay: 48.3ms;
}
.cssload-loading-circle:nth-child(2) {
  animation-delay: 96.6ms;
  -o-animation-delay: 96.6ms;
  -ms-animation-delay: 96.6ms;
  -webkit-animation-delay: 96.6ms;
  -moz-animation-delay: 96.6ms;
}
.cssload-loading-circle:nth-child(3) {
  animation-delay: 144.9ms;
  -o-animation-delay: 144.9ms;
  -ms-animation-delay: 144.9ms;
  -webkit-animation-delay: 144.9ms;
  -moz-animation-delay: 144.9ms;
}
.cssload-loading-circle:nth-child(4) {
  animation-delay: 193.2ms;
  -o-animation-delay: 193.2ms;
  -ms-animation-delay: 193.2ms;
  -webkit-animation-delay: 193.2ms;
  -moz-animation-delay: 193.2ms;
}
.cssload-loading-circle:nth-child(5) {
  animation-delay: 241.5ms;
  -o-animation-delay: 241.5ms;
  -ms-animation-delay: 241.5ms;
  -webkit-animation-delay: 241.5ms;
  -moz-animation-delay: 241.5ms;
}
.cssload-loading-circle:nth-child(6) {
  animation-delay: 289.8ms;
  -o-animation-delay: 289.8ms;
  -ms-animation-delay: 289.8ms;
  -webkit-animation-delay: 289.8ms;
  -moz-animation-delay: 289.8ms;
}
.cssload-loading-circle:nth-child(7) {
  animation-delay: 338.1ms;
  -o-animation-delay: 338.1ms;
  -ms-animation-delay: 338.1ms;
  -webkit-animation-delay: 338.1ms;
  -moz-animation-delay: 338.1ms;
}
.cssload-loading-circle:nth-child(8) {
  animation-delay: 386.4ms;
  -o-animation-delay: 386.4ms;
  -ms-animation-delay: 386.4ms;
  -webkit-animation-delay: 386.4ms;
  -moz-animation-delay: 386.4ms;
}
.cssload-loading-circle:nth-child(9) {
  animation-delay: 434.7ms;
  -o-animation-delay: 434.7ms;
  -ms-animation-delay: 434.7ms;
  -webkit-animation-delay: 434.7ms;
  -moz-animation-delay: 434.7ms;
}
.cssload-loading-circle:nth-child(10) {
  animation-delay: 483ms;
  -o-animation-delay: 483ms;
  -ms-animation-delay: 483ms;
  -webkit-animation-delay: 483ms;
  -moz-animation-delay: 483ms;
}
.cssload-loading-circle:nth-child(11) {
  animation-delay: 531.3ms;
  -o-animation-delay: 531.3ms;
  -ms-animation-delay: 531.3ms;
  -webkit-animation-delay: 531.3ms;
  -moz-animation-delay: 531.3ms;
}
.cssload-loading-circle:nth-child(12) {
  animation-delay: 579.6ms;
  -o-animation-delay: 579.6ms;
  -ms-animation-delay: 579.6ms;
  -webkit-animation-delay: 579.6ms;
  -moz-animation-delay: 579.6ms;
}
.cssload-loading-circle:nth-child(13) {
  animation-delay: 627.9ms;
  -o-animation-delay: 627.9ms;
  -ms-animation-delay: 627.9ms;
  -webkit-animation-delay: 627.9ms;
  -moz-animation-delay: 627.9ms;
}
.cssload-loading-row1 {
  top: 1.3px;
}
.cssload-loading-row2 {
  top: 18.95px;
}
.cssload-loading-row3 {
  top: 35.55px;
}
.cssload-loading-row4 {
  top: 53.2px;
}
.cssload-loading-row5 {
  top: 69.85px;
}
.cssload-loading-col1 {
  left: 1.25px;
}
.cssload-loading-col2 {
  left: 18.85px;
}
.cssload-loading-col3 {
  left: 35.5px;
}
.cssload-loading-col4 {
  left: 53.15px;
}
.cssload-loading-col5 {
  left: 69.8px;
}

@keyframes cssload-loading {
  0% {
    transform: scale(0);
  }
  27.28% {
    transform: scale(1);
  }
  36.36% {
    transform: scale(0.857);
  }
  54.55% {
    transform: scale(0.857);
  }
  63.64% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@-o-keyframes cssload-loading {
  0% {
    -o-transform: scale(0);
  }
  27.28% {
    -o-transform: scale(1);
  }
  36.36% {
    -o-transform: scale(0.857);
  }
  54.55% {
    -o-transform: scale(0.857);
  }
  63.64% {
    -o-transform: scale(0);
  }
  100% {
    -o-transform: scale(0);
  }
}

@-ms-keyframes cssload-loading {
  0% {
    -ms-transform: scale(0);
  }
  27.28% {
    -ms-transform: scale(1);
  }
  36.36% {
    -ms-transform: scale(0.857);
  }
  54.55% {
    -ms-transform: scale(0.857);
  }
  63.64% {
    -ms-transform: scale(0);
  }
  100% {
    -ms-transform: scale(0);
  }
}

@-webkit-keyframes cssload-loading {
  0% {
    -webkit-transform: scale(0);
  }
  27.28% {
    -webkit-transform: scale(1);
  }
  36.36% {
    -webkit-transform: scale(0.857);
  }
  54.55% {
    -webkit-transform: scale(0.857);
  }
  63.64% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
  }
}

@-moz-keyframes cssload-loading {
  0% {
    -moz-transform: scale(0);
  }
  27.28% {
    -moz-transform: scale(1);
  }
  36.36% {
    -moz-transform: scale(0.857);
  }
  54.55% {
    -moz-transform: scale(0.857);
  }
  63.64% {
    -moz-transform: scale(0);
  }
  100% {
    -moz-transform: scale(0);
  }
}
