.MenuItem button {
  padding: 44px 20px 10px;
  background-color: transparent;
  font-size: 16px;
  color: white;
  border: none;
}

.MenuItem button .Link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.MenuItem button .Link:hover,
.MenuItem button span:hover {
  box-shadow: 0px -20px 10px -7px pink;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  transition: 200ms;
}

.ItemList_A,
.ItemList_B,
.ItemList_C,
.ItemList_D,
.ItemList_E,
.ItemList_F {
  position: absolute;
  z-index: 80;
  top: 60px;
  list-style: none;
  background-color: #180b19;
  padding: 10px 9vw 10px 0;
  width: 90vw;
  left: 0;
  display: flex;
  justify-content: flex-end;
}

.ItemList_A li,
.ItemList_B li,
.ItemList_C li,
.ItemList_D li,
.ItemList_E li,
.ItemList_F li {
  line-height: 2;
  cursor: pointer;
  /* justify-content: space-evenly; */
  width: 16%;
  /* padding: 10px 10% 10px 40%; */
  background-color: #180b19;
}

.SubmenuItems {
  background-color: unset;
  border: unset;
}

.SubmenuItems .Link {
  color: white;
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
}

.SubmenuItems .Link:hover {
  box-shadow: 0px -20px 10px -7px pink;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  transition: 200ms;
}

@media (min-width: 820px) {
  .MenuItem button {
    font-size: 18px;
  }

  .SubmenuItems .Link {
    font-size: 17px;
  }
}
